<template>
  <div>
    <div class="pt-2">
      <div v-if="!image" class="wrapper-container mb-4">
        <div :class="`file-input ${codeRecognizeDisabled ? 'disabled' : ''}`">
          <input type="file"
                 accept="image/*"
                 capture="environment"
                 id="file"
                 :name="codeImageName || ''"
                 :disabled="codeRecognizeDisabled"
                 @change="uploadImage" ref="uploadInput">

          <label class="btn-select-image" for="file" :title="photoLabel" @click="clearImageFileValue">
            <i class="fa fa-camera mr-1"/>
            {{photoLabel}}
          </label>
        </div>
        <span v-if="codePopupHelper"
              class="help-link"
              onclick="window.showPopup('card-bonus-popup'); return false"
              :title="codeInputLabel"/>
      </div>

      <ul v-if="image" class="images-list">
        <li>
          <div class="image">
            <img :src="image.url" :alt="image.name">

            <span class="remove" title="Remove image" @click.prevent="() => onRemove()">X</span>
          </div>

          <p>{{image.name}}</p>
        </li>
      </ul>

      <div class v-if="loading">Please wait. Loading...</div>

      <div v-else-if="codes.length > 1" class="group-select-codes">
        <h2>{{photoSelectLabel}}</h2>
        <button
            v-for="code in codes"
            :class="codeClassName(code)"
            @click.prevent="() => {onSelect(code)}"
            :key="code"
        >{{code}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import {Statuses} from "./Statuses.model";
  import axios from "axios";

  export default {
    props: {
      codeImageName: {required: false, type: String},
      codePattern: {required: false, type: String},
      displayImage: {required: false, type: String},
      photoLabel: {required: false, type: String},
      photoSelectLabel: {required: false, type: String},
      selected: {required: true, type: Array},
      codePopupHelper: {required: false, type: Boolean},
      processingMessage: {required: false, type: String},
      channelId: {required: true, type: String},
      endpoint: {required: true, type: String},
    },

    data() {
      return {
        // todo: get from config file
        fileSizeLimit: 8, // Mb

        image: null,

        codes: [],
        reader: null
      };
    },

    created() {
      if (this.displayImage) {
        this.image = this.image = {
          name: 'new file',
          url: this.displayImage,
          file: null
        };
      }

      this.initFileReader();
    },

    computed: {
      codeRecognizeDisabled() {
        const url = new URL(location);

        return !!url.searchParams.get('code');
      },

      headers() {
        return {
          "X-CSRF-Token":
              document.querySelector("meta[name='csrf-token']") &&
              document.querySelector("meta[name='csrf-token']").content,
          "Content-Type": "multipart/form-data"
        };
      },
    },
    methods: {
      codeClassName(code) {
        return (
          "btn-select-codes" +
          (this.selected.indexOf(code) !== -1 ? " selected" : "")
        );
      },

      initFileReader() {
        this.reader = new FileReader();
      },

      onSelect(code) {
        this.$emit("code-select", code);
        // this.codes = [];
      },

      isFileSizeLimitOk(file) {
        return file && (file.size <= this.fileSizeLimit * 1024 * 1024);
      },

      clearImageFileValue: function () {
        this.$refs.uploadInput.value = "";
      },

      onRemove() {
        this.image = null;
        this.error = null;
        this.codes = [];
        this.$emit("code-reset");
      },

      uploadImage(event) {
        let file = event.target.files[0];

        if (!this.isFileSizeLimitOk(file)) {
          alert(
            `The image is too big. The max image size is ${this.fileSizeLimit}Mb`
          );
        } else {
          this.$emit("loading", true);
          this.$emit("code-reset");

          this.reader.onloadend = () => {
            this.image = {
              name: file.name,
              url: URL.createObjectURL(file),
              file: file
            };
            this.sendImage();
          };

          this.reader.onerror = error => {
            console.error("Error", error);

            this.$emit({
              status: Statuses.ERROR,
              message: error
            });
          };

          this.reader.readAsBinaryString(file);
        }
      },

      onMessage(message) {
        this.$emit("message", message);
      },

      sendImage() {
        let fd = new FormData();
        fd.append("file", this.image.file);
        fd.append("pattern", this.codePattern);
        fd.append('channel', this.channelId);

        axios({
          method: "post",
          url: this.endpoint,
          data: fd,
          headers: this.headers,
        }).catch((error) => {
          this.$emit("loading", false);
          this.onMessage({
            status: Statuses.ERROR,
            message: error.toString()
          });
        });
      }
    }
  };
</script>
