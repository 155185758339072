<template>
  <div>
    <div class="form-field">
      <label class="form-label" for="workflow_data_email">{{emailInputLabel}}</label>
      <input :placeholder="emailPlaceholder"
             autofocus
             @blur.prevent="validateField"
             v-model="email"
             type="email"
             :name="emailInputName"
             id="workflow_data_email">

      <div class="field-error" v-if="errors && errors.email">{{errors.email}}</div>

    </div>

    <div class="form-field">
      <input :placeholder="emailConfirmPlaceholder"
             type="email"
             :name="emailInputName.replace(/\]$/,'_confirmation]')""
             @blur.prevent="validateField"
             v-model="emailConfirmation"

             id="workflow_data_email_confirmation">

      <div class="field-error" v-if="errors && errors.emailConfirmation">{{errors.emailConfirmation}}</div>


      <div v-if="emailHint" class="hint">{{emailHint}}</div>
      <!-- hint -->
    </div>
    <!-- form-field -->
  </div>
</template>

<script>
  export default {
    props: [
      'isPhone',
      'emailInputName',
      'emailValue',
      'emailInputLabel',
      'emailPlaceholder',
      'emailConfirmPlaceholder',
      'emailHint',
      'emailMismatchError',
      'emailEmptyError',
      'emailDisplayMessage'
    ],
    data() {
      return {
        errors: {
          email: this.emailDisplayMessage ? this.emailDisplayMessage : '',
          emailConfirmation: null,
        },
        email: this.emailValue || '',
        emailConfirmation: this.emailValue || '',
      };
    },
    methods: {
      validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
      // todo: use universal validator here after move full page to VUE.js
      validateField(e) {
        switch (e.target.id) {
          case 'workflow_data_email':
            this.errors.email = !this.validateEmail(this.email) ? this.emailEmptyError : null;
            break;
          case 'workflow_data_email_confirmation':
            this.errors.emailConfirmation = !this.validateEmail(this.email)
              ? this.emailEmptyError
              : (this.email.toLowerCase() !== this.emailConfirmation.toLowerCase())
                ? this.emailMismatchError
                : null;
            break;
        }
      },
    },
  };
</script>
