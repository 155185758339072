<template>
  <div class="hint" v-if="showHint">
    <ul>
      <li v-for="line in hintText">
        {{line}}
      </li>
    </ul>
  </div>
</template>

<script>
  import {Statuses} from './Statuses.model';

  export default {
    props: {
      'codeTextHint': {required: true, type: String},
      'status': {required: false}
    },

    computed: {
      showHint() {
        return this.status !== Statuses.OK;
      },

      hintText() {
        return this.codeTextHint.split('\n').filter(line => !!line.trim());
      }
    }
  }
</script>
