<template>
  <div>
    <!--<div class="loader" v-if="loading">Loading...</div>-->

    <div class="form-field">
      <label class="form-label" for="phone">{{phoneInputLabel}}</label>
      <input type="tel"
             :data-no-validate-length="true"
             autofocus
             required
             @blur.prevent="validateField"
             :name="phoneInputName"
             id="phone"
             v-model="phone"
             :placeholder="phoneMask"
             :disabled="loading || (sec !== 0) ||  (status ==='confirmed')">

      <div class="field-error" v-if="errors && errors.phone">{{errors.phone}}</div>

      <div v-if="phoneHint" class="hint">{{phoneHint}}</div>

      <div v-if="phoneVerification" class="send-button">
        <button class="btn-claim-another-code"
                v-if="(status!=='confirmed') && (status!=='waiting_confirm')"
                :disabled="loading"
                @click.prevent="() => send('phone')">{{sendButtonLabel}}
        </button>

        <button class="btn-claim-another-code" v-if="status==='waiting_confirm'"
                :disabled="loading || (sec !== 0)"
                @click.prevent="() => send('phone')">{{resendText}}
        </button>
      </div>

    </div>

    <div v-if="phoneVerification && (status==='waiting_confirm')"
         class="form-field">
      <label for="code" class="form-label">{{confirmationInputLabel}}</label>
      <input type="number"
             id="code"
             :name="phoneInputName.replace(/\]$/,'_confirmation]')"
             placeholder="SMS confirmation code"
             :disabled="loading"
             v-model="confirmationCode">

      <div class="field-error" v-if="errors && errors.confirmationCode">{{errors.confirmationCode}}</div>

      <div class="send-button">
        <button class="btn-claim-another-code"
                v-if="status!=='confirmed'"
                :disaled="loading"
                @click.prevent="() => send('confirmationCode')">Verify
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';

  export default {
    props: {
      'isPhone': {required: true, type: Boolean},
      'phoneInputName': {required: true, type: String},
      'phoneInputLabel': {required: true, type: String},
      'phoneVerification': {required: false, type: Boolean, default: false},
      'inputName': {required: true, type: String},
      'phoneValue': {required: false, type: String},
      'phoneHint': {required: false, type: String},
      'phoneEmptyError': {required: true, type: String},
      'phoneMask': {required: false, type: String},
      'confirmationInputLabel': {required: false, type: String},
      'confirmationPlaceholder': {required: false, type: String},
      'sendButtonLabel': {required: false, type: String},
      'resendCountButtonLabel': {required: false, type: String},
      'resendButtonLabel': {required: false, type: String},
      'phoneDisplayMessage': {required: false, type: String}
    },

    data() {
      return {
        sec: 0,
        phone: this.phoneValue || '',
        phoneStored: '',
        confirmationCode: '',
        loading: false,
        status: null, // loading, waiting_confirm, confirmed, error
        errors: this.phoneDisplayMessage ? {phone: this.phoneDisplayMessage}: {},

        //for debug only
        BASE_URL: `${window.location.href}`,
      };
    },

    watch: {
      errors(old, newVal){
      },

      phoneDisplayMessage(old, newVal){
      },
    },

    methods: {
      runCountdown() {
        this.sec = 15;

        const interval = setInterval(() => {
          this.sec -= 1;
          if (this.sec < 1) {
            clearInterval(interval);
          }
        }, 1000)
      },

      send(type) {
        const headers = {
          'X-CSRF-Token': document.querySelector("meta[name='csrf-token']") && document.querySelector("meta[name='csrf-token']").content,
          'authenticity_token': document.querySelector("input[name='authenticity_token']") && document.querySelector("input[name='authenticity_token']").value,
        };

        let data = {};

        this.loading = true;
        this.errors = {};

        switch (type) {
          case 'phone':
            data = {
              phone: this.phone
            };

            axios.post(`${this.BASE_URL}/phone/register`, data, {headers: headers})
            .then(() => {
              this.status = 'waiting_confirm';
              this.loading = false;
              this.runCountdown();

              // store phone after success
              this.phoneStored = this.phone;
          })
            .catch(err => {
              this.loading = false;
              this.errors.phone = err;
              console.error('err', err)
            });
          // setTimeout(() => {
          //     this.status = 'waiting_confirm';
          //     this.loading = false;
          //     this.runCountdown();
          //
          //     // store phone after success
          //     this.phoneStored = this.phone;
          //   }, 2000);
            break;

          case 'confirmationCode':
            this.phone =  this.phoneStored;
            data = {
              phone: this.phoneStored,
              code: this.confirmationCode
            };

            axios.post(`${this.BASE_URL}/phone/verify`, data, {headers: headers})
              .then(() => {
                this.status = 'confirmed';
                this.loading = false;
              })
              .catch(err => {
                this.loading = false;
                this.errors.confirmationCode = err;
                console.error('err', err);
              });

            // setTimeout(() => {
            //   this.status = 'confirmed';
            //   this.loading = false;
            //   // save phone to form after success
            // }, 2000);
            break;
        }
      },

      validateField(e) {

        this.errors.phone = !this.phone ? this.phoneEmptyError : null;
      },
    },

    computed: {
      resendText() {
        return (this.sec > 0) ? this.resendCountButtonLabel.replace(/\%s/g, this.sec) : this.resendButtonLabel;
      }
    },
  }
</script>
