<template>
  <div class="form-field card-number-field pp0">
    <div class="form-group">
      <div class="d-flex flex-row">
        <label :for="codeInputName" class="form-label" v-text="label"/>
        <span class="help-link"
              @click.prevent="openModal"
              :title="labelHint"/>
      </div>
      <div class="d-flex flex-row align-center justify-center">
        <div class="flex-grow-1">
          <input
              ref="_field"
              type="text"
              autocomplete="off"
              :placeholder="codePlaceholder"
              maxlength="10"
              size="10"
              :class="`PIN10 PIN10 ${className ? `input-${className}` : ''}`"
              :id="codeInputName"
              :disabled="loading || (validated && !hasError)"
              @input="onChange"
              v-model="code"
          >
          <div v-if="validated && codeMessage"
               :class="`form-field-notify ${notifyClassName}`"
               v-html="codeMessage"/>

          <template v-if="validated && !hasError">
            <div class="my-2">
              <button class="btn btn-danger text-uppercase px-4 rounded-sm" @click.prevent="clearForm">Cancel</button>
            </div>
          </template>

          <input type="hidden" :value="code" :name="codeInputName"/>
        </div>
        <div class="pl-2 h3">
          <template v-if="loading">
            <div class="pt-2">
              <i class="fa fa-spinner fa-spin text-black-50"/>
            </div>
          </template>
          <template v-else-if="hasError">
            <i class="pt-2 fa fa-exclamation-circle text-danger"/>
          </template>
          <template v-else-if="validated">
            <i class="pt-2 fa fa-check text-success"/>
          </template>
          <template v-else>
            <button
                @click.prevent="checkCode"
                class="btn btn-outline-primary btn-md m-0 py-2"
                :disabled="loading || !code.length"
            >
              Check Code
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {get} from "lodash";

export default {
  name: 'PropaneValidation',

  props: {
    label: {
      required: true,
      type: String,
    },
    codeInputValue: {
      required: false,
      type: String,
    },
    codeInputName: {
      required: true,
      type: String,
    },
    codePlaceholder: {
      required: true,
      type: String,
    },
    codeError: {
      required: false,
      type: String,
    },
    labelHint: {
      required: false,
      type: String,
      default: 'Where is code?'
    },
    validateEndpoint: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      code: '',
      loading: false,
      validated: false,
      hasError: false,
      codeMessage: '',

      $submitButton: null,
      $otherFields: [],
    };
  },

  mounted() {
    this.$submitButton = document.querySelector('input[type="submit"]');
    this.$otherFields = [...document.querySelectorAll('input[type="text"]')].filter(field => field !== this.$refs._field);

    if (this.codeInputValue) {
      this.code = this.codeInputValue;
      this.checkCode();
    }

    // init error message if any
    if (this.codeError) {
      this.validated = true;
      this.hasError = true;
      this.codeMessage = this.codeError;
    }

    this.$nextTick(() => {
      this.$otherFields.forEach($field => {
        $field.addEventListener('keyup', this.checkFieldsStatus);
      });

      this.checkFieldsStatus();
    });
  },

  computed: {
    headers() {
      return {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")?.content,
        "Authenticity-Token": document.querySelector("input[name='authenticity_token']").value,
      };
    },

    className() {
      if (this.validated) {
        return this.hasError ? 'error' : 'success';
      }

      return '';
    },

    notifyClassName() {
      const mappingOverrides = {
        'success': 'form-field-info'
      };

      return mappingOverrides[this.className] || `form-field-${this.className}`;
    }
  },

  methods: {
    openModal() {
      $('.card-bonus-popup').addClass('opened');
      return false;
    },

    onChange() {
      this.hasError = false;
      this.codeMessage = '';
      this.validated = false;
    },

    checkFieldsStatus() {
      const otherFieldsAreFilled = this.$otherFields.filter($field => !!$field.value).length === this.$otherFields.length;

      if (otherFieldsAreFilled  && this.code.length && this.codeMessage && !this.hasError) {
        this.toggleSubmitDisabled(false);
      } else {
        this.toggleSubmitDisabled(true);
      }
    },

    toggleSubmitDisabled(disabled) {
      if (this.$submitButton) {
        this.$submitButton.disabled = disabled || false;
      }
    },

    handleErrors(message, errors) {
      this.codeMessage = message || get(errors, 'serial_number', ['something went wrong']).join(' ');
      this.hasError = true;
      this.toggleSubmitDisabled(true);
    },

    handleSuccess(message) {
      this.codeMessage = message;
      this.checkFieldsStatus();
    },

    checkCode() {
      this.loading = true;

      axios({
        method: 'post',
        url: this.validateEndpoint,
        data: {
          serial_number: this.code,
        },
        headers: this.headers,
      }).then(({data: {success, message, errors}}) => {
        if (success) {
          this.handleSuccess(message);
        } else {
          this.handleErrors(message, errors);
        }
      }).catch(error => {
        this.handleErrors(error.response.data.errors);
      }).finally(() => {
        this.loading = false;
        this.validated = true;
      });
    },

    clearForm() {
      this.code = '';
      this.validated = false;
      this.hasError = false;
      this.codeMessage = '';

      // clear other fields
      this.$otherFields.forEach($field => {
        const $error = $field.nextElementSibling;
        $error && $error.remove();

        $field.value = '';
        $field.classList.remove('input-error');
      });

      // disable submit button
      this.toggleSubmitDisabled(true);
    }
  },
}
</script>
