<template>
  <span class="loader-wrapper position-absolute">
    <template v-if="text">
      <span class="loader-wrapper__text" >{{ text }}</span>
    </template>
    <template v-else>
      <img src="/spinner.gif" alt="Loading" />
    </template>
  </span>
</template>

<script>
export default {
  name: "LoadingOverlay",
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
