<template>
  <div class="switcher-comp">
    <h6>{{titleText}}</h6>

    <div class="labels">
      <label for="email">
        <input type="radio" id="email" value="email" v-model="type">
        {{emailLabel}}
      </label>

      <label for="phone">
        <input type="radio" id="phone" value="phone" v-model="type">
        {{phoneLabel}}
      </label>
    </div>

    <div>
      <br>
      <Email v-if="type==='email'"
             v-bind="$props"/>

      <Phone v-if="type==='phone'"
             v-bind="$props"/>
    </div>

    <input type="hidden"
           :name="inputHiddenName"
           :value="type === 'phone'"/>
  </div>
</template>

<script>
  import Phone from './Phone.vue';
  import Email from './Email.vue';


  export default {
    props: {
      inputName: {required: true, type: String},
      inputHiddenName: {required: true, type: String},
      isPhone: {
        type: Boolean,
        required: false
      },
      titleText: {required: true, type: String},

      emailLabel: String,
      phoneLabel: String,

      emailInputName: String,
      emailInputLabel: String,
      emailPlaceholder: String,
      emailConfirmPlaceholder: String,
      emailHint: String,
      emailMismatchError: String,
      emailEmptyError: String,
      emailValue: String,
      emailDisplayMessage: {required: false, type: String},

      phoneVerification: {required: false, type: Boolean, default: false},
      phoneConfirmationMessage: {required: true, type: String},
      phoneInputName: String,
      phoneInputLabel: String,
      phoneHint: String,
      phoneMask: String,
      phoneValue: String,
      phoneDisplayMessage: {required: false, type: String},

      confirmationInputLabel: {required: false, type: String},
      confirmationPlaceholder: {required: false, type: String},
      sendButtonLabel: {required: false, type: String},
      resendCountButtonLabel: {required: false, type: String},
      resendButtonLabel: {required: false, type: String},
    },

    components: {
      Email,
      Phone,
    },

    data() {
      return {
        type: this.isPhone ? 'phone' : 'email',
      };
    },

    created() {
      setTimeout(() => {
        const $form = $(`input[name="${this.inputHiddenName}"]`).closest('form');

        $form.bind('submit', () => {
          if (this.type === 'phone') {
            const phone = $(`input[name="${this.phoneInputName}"]`).val();
            const message = this.phoneConfirmationMessage.replace("%{phone}", phone);
            return confirm(message);
          } else {
            return true;
          }

        });
      }, 600);
    }
  };
</script>
