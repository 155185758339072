<template>
<div>
  <input type="hidden"
         :name="codeHiddenName"
         v-model="value"
  >
</div>
</template>

<script>
export default {
  props: {
    'codeHiddenName': {required: true, type: String},
    'value': {required: false, type: Boolean}
  },

  data() {
    return {};
  },
}
</script>
