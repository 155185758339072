import Vue from 'vue';
import VueMask from 'v-mask';

import CodePictureComponent from './vue_components/ocrComponent/index.vue';
import SwitcherComponent from './vue_components/phoneEmailSwitcher/index.vue';
import MultipleOCR from './vue_components/muplipleSerialOCR/index.vue';
import PropaneValidation from './vue_components/validationComponent/propane/index.vue';

Vue.use(VueMask);

new Vue({
  el: '#code-picture-component',
  components: {
    'code-picture': CodePictureComponent,
    'multiple-ocr-codes': MultipleOCR,
  }
});

new Vue({
  el: '#validation-code-component',
  components: {
    'propane-code': PropaneValidation,
  }
});

new Vue({
  el: '#switcher-component',
  components: {
    switcher: SwitcherComponent,
  }
});

window.showPopup = function(container) {
  $('.' + container).addClass('opened');
};
