<template>
  <div>
    <div class="multi-progress" :style="bgStyle" :title="progressHint">
      <img :src="mask" alt="mask" class="multi-progress__mask"/>
    </div>
  </div>
</template>

<script>
import bg from 'images/progress_bg';
import mask from 'images/progress_mask3';

export default {
  props: {
    value: {
      required: true,
      type: Number,
    },
    progressHint: {required: false, type: String, default: 'Progress'},
  },
  data() {
    return {
      bg,
      mask,
    };
  },

  computed: {
    bgStyle() {
      return {
        'background-image': `url(${this.bg})`,
        'background-position': `${this.value}% 0%`,
      }
    }
  }
}
</script>
