<template>
  <label v-if="codeInputLabel"
           class="form-label"
           :for="rid">{{codeInputLabel}}
  </label>
</template>

<script>
export default {
  props: {
    'rid': {required: true, type: String},
    'codeInputLabel': {required: false, type: String},
    'codeInputName': {required: true, type: String}
  },

  data() {
    return {
      scode: '',
      validationError: null,
    };
  },
}
</script>
